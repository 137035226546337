import {axiosGet} from "@/utils/request.js";

export const token = (data) => {
    return axiosGet('/api/token', data);
}

export const refreshToken = () => {
    return axiosGet('/api/token/refresh');
}

// 声量趋势
export const Trend = (data) => {
    return axiosGet('/api-mengniu/stats/trend', data)
}
// 平台分布
export const distribution = (data) => {
    return axiosGet('/api-mengniu/stats/distribution', data)
}
// 蒙情感分布
export const sentiment = (data) => {
    return axiosGet('/api-mengniu/stats/sentiment', data)
}
// 蒙⽜KOL表现
export const raw = (data) => {
    return axiosGet('/api-mengniu/query/raw', data)
}
// 蒙⽜负⾯舆情
export const negative = (data) => {
    return axiosGet('/api-mengniu/query/raw/negative', data)
}
// 世界杯词云展示
export const word = (data) => {
    return axiosGet('/api-mengniu/word/cloud', data)
}

// 蒙牛词云展示
export const mengniuWord = (data) => {
    return axiosGet('/api-mengniu/word/cloud/mengniu', data)
}
// 热帖排⾏
export const hot = (data) => {
    return axiosGet('/api-mengniu/query/raw/hot', data)
}

// 微博搜索排⾏
export const weibo = (data) => {
    return axiosGet('/api-mengniu/weiboHot', data)
}

// 统计⾏
export const statistics = (data) => {
    return axiosGet('/api-mengniu/statistics', data)
}