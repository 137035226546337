
export function formatTime() {
  let getDate = new Date();
  let nowYear = getDate.getFullYear();
  let nowMonth = getDate.getMonth() + 1;
  let nowDay = getDate.getDate();
  let nowWeek = getDate.getDay();
  let a = new Array("日", "一", "二", "三", "四", "五", "六");
  let str = "星期" + a[nowWeek];
  let getNowDate = nowYear + '-' + nowMonth + '-' + nowDay + ' ';
  return getNowDate;
}

export function formatMonth() {
  //创建现在的时间
  var data = new Date();
  //获取年
  var year = data.getFullYear();
  //获取月
  var mon = data.getMonth() + 2;
  var arry = new Array();
  
  for (var i = 0; i < 6; i++) {
    mon = mon - 1;
    if (mon <= 0) {
      year = year - 1;
      mon = mon + 12;
    }
    if (mon < 10) {
      mon = "0" + mon;
    }
    arry[i] = year + "-" + mon;
  }

  return arry;
}




/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}


export const setStore = (name, content) => {
  let name1 = 'yoniot-' + name;
  let obj = {
    dataType: typeof (content),
    content: content,
    type: 'session',
    datetime: new Date().getTime()
  }
  window.sessionStorage.setItem(name1, JSON.stringify(obj));
  //window.localStorage.setItem(name, JSON.stringify(obj));
}
/**
 * 浏览器判断是否全屏
 */
 export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * 浏览器判断是否全屏
 */
 export const fullscreenEnable = () => {
  return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
}

/**
 * 浏览器全屏
 */
 export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}

/**
 * 浏览器退出全屏
 */
 export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}

